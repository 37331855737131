.menu-aside {
    ul {
        list-style: none;
    }

    a {
        color: $text-color;

        &:hover,
        &.act {
            color: $color-brand;
        }
    }
}
