.hh-slider.frame-layout-0 {
    &:hover {
        .slider-button {
            opacity: 1;
        }
    }

    .slider-button {
        opacity: 0;
        transition: opacity 400ms;
    }
}

/* HH SLIDER BUTTONS */
.hh-slider .slider-button {
    background-image: none;
    transform-origin: center center;
    top: calc(50% - 53px);
    height: 108px;
    min-width: 10px;
    max-width: 56px;
    width: 2.9vw;
    padding-top: 50px;
    overflow: hidden;

    &.slider-button-prev {
        left: 4.2vw;
    }

    &.slider-button-next {
        right: 4.2vw;
        transform: scaleX(-1);
    }

    &::after {
        content: "";
        display: block
    }

    &::before,
    &::after {
        background-color: #fff;
        content: "";
        display: block;
        width: 200%;
        height: 4px;
        transform: rotate(45deg);
        transform-origin: 0 100%;
    }

    &::after {
        transform: rotate(-45deg);
        transform-origin: 0 0;
    }

    @media screen and (max-width: 960px) {
        &.slider-button-prev { left: 20px; }
        &.slider-button-next { right: 20px; }
    }

    @media screen and (max-width: 320px){
        &.slider-button-prev { left: 10px; }
        &.slider-button-next { right: 10px; }
    }
}

.tns-nav {
    &.pagination {
        margin-top: 26px;
    }
    .tns-nav-active {
        background-color: $color-brand2;
    }
    button {
        background-color: #ccc;
        border: 0;
        height: 8px;
        width: 8px;
    }
}

/* frame-layout-image-3col-zoom */
.frame.frame-layout-image-3col-zoom {
    $animationSpeed: 1500ms;
    background:#000;
    padding:44px 0 30px 0;

    .tns-outer { position:relative; top:0; }

    // Header
    header { position:relative; top:0; text-align:center; margin-bottom:6vh; }
    header :first-child { margin-top:3vh; }

    // Header - Main Headline
    header h1, header h2, header h3 { font-size:index($h1-font-sizes, xlarge); color:#fff; }

    // Header - Subheadline
    header h1 + h2, header h2 + h3, header h3 + h4 { font-size:nth($h2-font-sizes, 1); color:$color-brand2; letter-spacing:0; }

    // Slider Buttons
    .slider-button { top:-20px; transform:translateY(-100%); transform-origin:center top; }
    .slider-button.slider-button-next { transform:translateY(-100%) scaleX(-1); }
    .slider-button::before, .slider-button::after { width:50%; }

    @media screen and (max-width:640px){
        .slider-button { top:60%; }
        .slider-button::before, .slider-button::after { width:100%; }
    }

    // Slider Elements
    .slider-slide {
        // Slide - Image
        .image-embed-item { display:block; }
        .image-embed-item img {
            display:block; transition:all $animationSpeed;
            transform-origin:center top;
            transform:scale(0.8) translateY(25%);

            @media screen and (max-width:640px){
                transform:scale(1);
                width:82%;
                margin:0 auto;
            }
        }

        &.tns-slide-active .image-embed-item img { transform-origin:left top; } // Active Slide Left
        &.tns-slide-active + .tns-slide-active .image-embed-item img {  }       // Active Slide Center
        &.tns-slide-active + .tns-slide-active + .tns-slide-active .image-embed-item img { transform-origin:right top; } // Active Slide Right

        @media screen and (max-width:640px){
            &.tns-slide-active .image-embed-item img { transform-origin:center top; }
        }

        &.io--active {
            .image-embed-item img {
                transform:scale(1);
            }
        }

        // Slide - Figcaption
        figcaption {
            position:relative; top:-30px; left:0; right:0; bottom:0; z-index:-1;
            margin-top:15px;
            color:#fff; text-align:center;
            width:100%;
            transition:all $animationSpeed;

            opacity:0;
            transform:scale(.5);

            p { display:none; }
            p:first-of-type { display:block; text-align:center; }

            @media screen and (max-width:640px){
                top:0; z-index:999; opacity:1; transform:scale(1);
            }
        }

        &.io--active {
            figcaption {
                opacity:1; top:0px;
                transform:scale(1);
            }
        }
    }
}

/* START: image-2col-fade-topbottom */
.frame.frame-layout-image-2col-fade-topbottom {
    // Slider Type Images
    &.frame-without-container .slider-type-1 .slider-pagination { position: absolute; right: 12vw; } // Slider-Type-1 Pagination

    .slider-type-1 {
        .tns-inner {
            overflow: visible;
        }

        .slider-pagination {
            color: $color-brand2;
            font-size: 1.5em;
            padding-bottom: 5px;
        }

        .slider-slide {
            > .row {
                display: flex;

                > .col {
                    opacity: 0;
                    transition: opacity 500ms, transform 1500ms;

                    img {
                        width: 100%;
                    }

                    @media screen and (min-width: 700px) {
                        &:first-child {
                            width: 41%;
                            transform: translateY(-100%);
                        }

                        &:nth-child(2) {
                            margin-top: 6%;
                            width: 59%;
                            transform: translateY(100%);
                        }
                    }
                }

                &.caption {
                    padding: 50px 12vw;

                    figcaption {
                        left: initial;
                        position: relative;
                        top: initial;
                    }

                    @media screen and (min-width: 1921px) {
                        max-width: 1520px;
                        padding-left: 230px;
                        padding-right: 230px;
                    }
                }
            }

            &.tns-fadeOut {
                > .row {
                    > .col {
                        &:first-child {
                            transform: translateY(0);
                        }

                        &:nth-child(2) {
                            transform: translateY(0);
                        }
                    }
                }
            }

            &.tns-slide-active {
                > .row {
                    > .col {
                        opacity: 1;

                        &:first-child {
                            transform: translateY(0);
                        }

                        &:nth-child(2) {
                            transform: translateY(0);
                        }
                    }
                }
            }
        }

        .slider-button {
            z-index: 1000;
            width: 28px;

            &::before,
            &::after {
                background-color: $color-grey-dark;
                height: 2px;
            }

            &:hover {
                &::before,
                &::after {
                    background-color: $color-brand2;
                }
            }

            @media screen and (min-width: 700px) {
                bottom: initial;
                top: -35px;
                width: 16px;

                &.slider-button-prev {
                    left: 44%;
                }

                &.slider-button-next {
                    left: 46%;
                    margin-left: 20px;
                }
            }
        }

        .tns-outer + .ce-bodytext {
            padding: 50px 12vw;
            max-width: 1500px;
        }

        @media screen and (min-width: 1921px) {
            .tns-outer + .ce-bodytext {
                max-width: 1520px;
                padding-left: 230px;
                padding-right: 230px;
            }
        }
    }

    // Slider Type Content
    .slider-type-2 {
        .tns-inner { overflow: visible; }

        .slider-slide {
            .ce-gallery {
                .ce-inner {
                    display: flex;

                    .ce-column {
                        width:50%;
                        //opacity: 0;
                        transition: opacity 500ms, transform 1500ms;

                        &:first-child { /*transform: translateY(-100%);*/ }
                        &:nth-child(2) { margin-top: 6%; /*transform: translateY(100%);*/ }
                        img { width: 100%; }
                    }
                }

                &.tns-fadeOut {
                    .ce-inner {
                        .ce-column {
                            &:first-child { transform: translateY(0); }
                            &:nth-child(2) { transform: translateY(0); }
                        }
                    }
                }

                &.tns-slide-active {
                    .ce-inner {
                        .ce-column {
                            //opacity: 1;

                            &:first-child { transform: translateY(0); }
                            &:nth-child(2) { transform: translateY(0); }
                        }
                    }
                }
            }
        }

        .slider-button {
            top: initial;
            width: 16px;
            position:absolute;
            top:-40px; left:auto; bottom:auto;

            &::before,
            &::after {
                background-color: $color-grey-dark;
                height: 2px;
            }

            &:hover {
                &::before,
                &::after {
                    background-color: $color-brand2;
                }
            }

            &.slider-button-prev { right: 50px; }
            &.slider-button-next { right: 0; }
        }

        .tns-outer + .ce-bodytext {
            padding: 50px 12vw;
            max-width: 1500px;
        }

        @media screen and (min-width: 1921px) {
            .tns-outer + .ce-bodytext {
                max-width: 1520px;
                padding-left: 230px;
                padding-right: 230px;
            }
        }
    }

    // Slider Type Folder
    .slider-type-3 {

    }
}
/* END: image-2col-fade-topbottom */
