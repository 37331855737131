/* GRIDELEMENTS */
.grid-container {
    > header { padding:0; }

    .grid-row {
        display:flex;
        flex-wrap:wrap;
        flex-direction:row;

        .grid-col { box-sizing:border-box; padding:2vw; padding-left:0; }
        .grid-col-1 { flex-basis:100%; width:100%; }
        .grid-col-2 { flex-basis:16.6%; width:16.6%; }
        .grid-col-3 { flex-basis:25%; width:25%; }
        .grid-col-4 { flex-basis:33.3%; width:33.3%; }
        .grid-col-5 { flex-basis:41.6%; width:41.6%; }
        .grid-col-6 { flex-basis:50%; width:50%; }
        .grid-col-7 { flex-basis:58.3%; width:58.3%; }
        .grid-col-8 { flex-basis:66.6%; width:66.6%; }
        .grid-col-9 { flex-basis:75%; width:75%; }
        .grid-col-10 { flex-basis:83.3%; width:83.3%; }
        .grid-col-11 { flex-basis:91.6%; width:91.6%; }
        .grid-col-12 { flex-basis:100%; width:100%; }

        /* MOBILE - TABLET */
        @media screen and (max-width:1024px){
            div.grid-col:not(.grid-col-1) { flex-basis:50%; width:50%; }
        }

        /* MOBILE - SMARTPHONE */
        @media screen and (max-width:640px){
            div.grid-col:not(.grid-col-1) { flex-basis:100%; width:100%; padding-right:0; }
        }
    }
}
