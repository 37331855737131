* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    /* disable callout, image save panel (popup) */
    -webkit-tap-highlight-color: transparent;
    /* "turn off" link highlight */
}

a:focus {
    outline: 0; // Firefox (remove border on link click)
}

p:last-child {
    margin-bottom: 0;
}

.media {
    width: 100%;
}

.io--hidden { display:none; }

/* -------------------------------- */
/* START: Arrows */
i.arrow {
    border: solid #fff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    height: 15px;
    padding: 3px;
    width: 15px;
}

.arrow.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.arrow.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
/* END: Arrows */
/* -------------------------------- */

#center-page {
    margin: 0 auto;
    max-width: 1920px;
    overflow: hidden;
}

#header {
    height: 100vh;
    left: 0;
    max-width: 100%;
    min-height: 500px;
    padding-left: 12vw;
    padding-right: 12vw;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 800;

    @media screen and (min-width: 1921px) {
        padding-left: 230px;
        padding-right: 230px;
    }
}

@keyframes scroll-left-right {
    0%   { left: 20%; }
    50%  { left: 80%; }
    100% { left: 20%; }
}

@keyframes scroll-lines-left {
    0%   { width: 5%; }
    50%  { width: 60%; }
    100% { width: 5%; }
}

@keyframes scroll-lines-right {
    0%   { width: 60%; }
    50%  { width: 5%; }
    100% { width: 60%; }
}

.scroll {
    display: none;

    @media screen and (min-width: 600px) {
        bottom: 0;
        display: block;
        position: absolute;
        right: 2vw;
        transform: rotate(-90deg) translateX(100%);

        > span {
            display: inline-block;
            vertical-align: middle;
        }

        .scroll-text {
            color: #fff;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0.5px;
            margin-left: 20px;
        }

        .scroll-line {
            height: 1px;
            width: 56px;

            &::before,
            &::after {
                background-color: #fff;
                content: " ";
                height: 1px;
                position: absolute;

                animation-duration: 4s;
                animation-iteration-count: infinite;
            }

            &::before {
                left: 0;
                width: 20%;
                animation-name: scroll-lines-left;

            }

            &::after {
                right: 0;
                width: 60%;
                animation-name: scroll-lines-right;
            }

            .circle {
                border: 2px solid $color-brand2;
                border-radius: 100%;
                height: 12px;
                left: 20%;
                position: absolute;
                top: 0.5px;
                width: 12px;
                transform: translate(-50%, -50%);

                animation-name: scroll-left-right;
                animation-duration: 4s;
                animation-iteration-count: infinite;
            }
        }
    }

    @media screen and (min-width: 1921px) {
        right: 38px;
    }
}

#logo {
    margin-top: 65px;
    min-width: 170px;
    text-align: right;
    width: 100%;
    transform: translateX(6vw);

    img {
        display: inline-block;
        width: 70%;
    }

    @media screen and (min-width: 600px) {
        margin-top: 10vh;

        img {
            width: auto;
        }
    }

    @media screen and (min-width: 1921px) {
        margin-top: 108px;
        transform: translateX(115px);
    }
}

.top-box {
    bottom: 8vh;
    color: rgba(255,255,255,0.6);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    left: 0;
    padding-left: $container-gap-horizontal;
    position: absolute;
    width: 100%;

    nav {
        font-size: 1rem;
        font-weight: 600;
        text-transform: uppercase;

        > ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: -10px;
        }

        li {
            &.active {
                > a {
                    opacity: 1;
                }
            }

            .separator {
                color: $color-brand2;
                margin-left: -2px;
            }
        }

        a {
            color: #fff;
            opacity: 0.5;
            padding: 10px;

            &:hover,
            &.active {
                opacity: 1;
            }
        }
    }

    .menu-breadcrumb {
        margin-bottom: 50px;
        width: 100%;

        a {
            color: $color-brand2;
        }
    }

    .image-description {
        font-size: 42px;
        line-height: 1.2;
        margin-bottom: 60px;
        margin-left: -2px;
        opacity: 1;
        padding-right: 5px;
        width: 100%;
        transition: all 500ms;

        &.hide {
            opacity: 0;
        }

        p {
            font-size: 1em;
            font-weight: 600;
            line-height: 1.2;
        }

        @media screen and (min-width: 600px) {
            font-size: 5.5vw;
            padding-right: 110px;
        }

        @media screen and (min-width: 1300px) {
            padding-right: 40%;
        }
    }

    .box-item {
        &:not(:last-child) {
            margin-right: 3vw;
        }
    }

    .box-item-header {
        display: none;
    }

    .menu-additonaltopmenu {
        display: none;
    }

    @media screen and (min-width: 600px) {
        padding-left: 2*$container-gap-horizontal;

        .box-item-header {
            display: block;
            font-size: 1.125rem;
            font-weight: 600;
            margin-bottom: 1.3vw;
            text-transform: uppercase;
        }

        .menu-additonaltopmenu {
            display: block;
        }
    }

    @media screen and (min-width: 1921px) {
        bottom: 86px;
        padding-left: 2*$container-gap-horizontal-overlayout;

        .box-item {
            &:not(:last-child) {
                margin-right: 58px;
            }
        }

        .box-item-header {
            margin-bottom: 25px;
        }

        .menu-breadcrumb {
            margin-bottom: 72px;
        }

        .image-description {
            font-size: 106px;
        }
    }
}

#searchbox {
    position: relative;

    .tx-indexedsearch-searchbox-sword {
        line-height: 2em;
        width: 100%;
    }

    .tx-indexedsearch-searchbox-button {
        position: absolute;
        right: 0;
        top: 0;
    }
}

.custom-breadcrumb {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 50px;

    ul {
        color: $color-brand2;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        margin-left: -10px;
        text-transform: uppercase;

        li {
            padding: 0 10px;
        }

        a {
            color: $color-brand2;
        }

        @media screen and (min-width: 600px) {
            li {
                padding: 10px;
            }
        }
    }

    .separator {
        color: $color-brand2;
        display: inline-block;
        transform: translateX(8px);
    }

    @media screen and (min-width: 1921px) {
        margin-bottom: 72px;
    }
}

#banner {
    min-height: 100vh;
    margin: 0 auto;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    width: 100vw;
    z-index: 500;

    > .frame-type-image,
    > .hh-slider {
        min-height: 500px;

        img {
            height: 100vh;
            min-height: 500px;
            object-fit: cover;
            width: 100vw;
        }
    }

    .ce-textpic.ce-above .ce-gallery {
        margin-bottom: 0;
    }

    .banner-overlay {
        position: absolute;
        left:50%;
        top:50%;
        transform:translate(-50%, -50%);
    }

    .header-banner {
        color:#fff;
    }
}

.js #banner {
    figcaption {
        display: none;
    }
}

.main {
    position: relative;
    width: 100%;
    z-index: 600;

    ul, ol {
        line-height: 1.2;
        margin-left: 20px;
    }

    ul {
        // list-style: square outside url("sqpurple.gif");
        list-style: none;

        > li {
            padding-left: 5px;
            padding-top: 0.3em;
            padding-bottom: 0.3em;

            &::before {
                content: "-";
                font-size: 2em;
                left: -0.5em;
                position: absolute;
                top: 50%;
                transform: translateY(-57%);
            }
        }
    }
}

.main .ce-textpic .ce-bodytext>header {
    margin-bottom: 20px;
}

.hh-slider {
    z-index: 100;
}

.link-download-icon {
    &::before {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='35.15px' height='37.47px' viewBox='0 0 35.15 37.47' enable-background='new 0 0 35.15 37.47' xml:space='preserve'%3e%3crect x='9.867' fill='%231F407A' width='15.417' height='2.453'/%3e%3crect x='9.867' y='3.503' fill='%231F407A' width='15.417' height='3.928'/%3e%3cpolygon fill='%231F407A' points='25.284,23.903 25.284,8.482 9.867,8.482 9.867,23.903 0,23.903 17.573,37.47 17.578,37.47 35.15,23.903 '/%3e%3c/svg%3e");
        background-size: 20px 20px;
        background-position: center;
        background-repeat: no-repeat;
        border: 1px solid $color-brand2;
        border-radius: 100%;
        content: " ";
        display: inline-block;
        height: 30px;
        margin-right: 7px;
        margin-bottom: -10px;
        position: relative;
        width: 30px;
    }
}

.frame {
    > header {
        display: flex;
        flex-direction: column-reverse;

        .subheader {
            padding-bottom: 4px;
        }
    }
}

.frame-type-text {
    p {
        max-width: 1000px;

        &.text-center {
            margin-left: auto;
            margin-right: auto;

            .btn-with-line {
                a::after {
                    margin: 0 auto;
                }
            }
        }
    }
}

.be-default_with_side_nav {
    #center-page {}

    .content-main {
        display: flex;
        margin-left: $container-gap-horizontal;
    }

    .aside-left {
        margin-top: $container-gap-vertical;
        width: 20%;

        #guestbook {
            height: 300px;
            width: 100%;
        }
    }

    .main {
        width: 80%;
    }

    .frame-default,
    .frame-type-html > iframe,
    .tx-hh-ext-jobsdahoam {
        margin-right: $container-gap-horizontal + 4vw;
        max-width: 100vw - ($container-gap-horizontal + 4vw);
    }
}

.statements_container {
    padding-top: 10px;
    position: relative;

    > h3,
    > strong {
        color: #bdbcbc;
        padding-bottom: 5px;
    }
}

.statements {
    border: 3px solid #d0d0d0;
    padding: 6%;
    width: 78%;
}

/* START: backToTop button; */
#backToTop {
    background-image: url("../Icons/icon_back_to_top.svg");
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    display: block;
    height: 50px;
    margin: 0 auto;
    margin-bottom: 130px;
    width: 50px;
}
/* END: backToTop button; */
