/* FRAME CONTAINER large */
.frame.frame-container-large {
    margin-left: 5.31vw;
    margin-right: 5.31vw;

    @media screen and (min-width: 1921px) {
        margin-left: 102px;
        margin-right: 102px;
    }
}

/* FRAME CONTAINER fullwidth */
.frame.frame-fullwidth,
.frame.frame-fullwidthNoSpaceAfter {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    // overflow-x: hidden;

    .ce-gallery {
        width: calc(100% + 20px);
    }
}

.frame:nth-child(1).frame-fullwidth,
.frame:nth-child(1).frame-fullwidthNoSpaceAfter {
    margin-top:-50px;

    .type-top { margin:0 !important; }
}

.frame.frame-fullwidthNoSpaceAfter {
    margin-bottom: 0;
    overflow: hidden;

    .ce-gallery {
        margin-bottom: -7px;
    }
}

/* CE DEFAULT GALLERY */
.frame.frame-layout-galleryFit {
    .ce-textpic {
        display:block;

        .ce-gallery {
            width:100%;
            display:flex;
            flex:1 1 auto;
            flex-direction:row;
            flex-wrap: wrap;
            justify-content:flex-start;
            align-content:flex-start;
            align-items: stretch;
            overflow:hidden;

            @media screen and (max-width:480px){
                display:block;
            }

            .ce-column {
                width:50%; transform-origin:center center; display:block; position:relative;

                .image { display:block; width:100%; height:100%; }
                .image img { object-fit: cover; object-position: center center; height:100%; width:100%; }

                &:nth-child(1){  }
                &:nth-child(2){  }
                &:nth-child(3){ width:100%; }
                &:nth-child(4) .image {  }
                &:nth-child(4) .image img { height:66%; }
                &:nth-child(5) .image {  }
                &:nth-child(5) .image img { height:134%; }
                &:nth-child(6) .image { transform:translateY(-34%); }
                &:nth-child(6) .image img { height:134%; }
                &:nth-child(7) .image { transform:translateY(34%); }
                &:nth-child(7) .image img { height:66%; }

                @media screen and (max-width:480px){
                    width:100%;

                    // Overrides
                    &:nth-child(odd) .image, &:nth-child(even) .image { height:auto; transform:none; }
                    &:nth-child(odd) .image img, &:nth-child(even) .image img { object-fit:initial; height:auto; }
                }
            }
        }
    }
}

/* OTHER ELEMENTS */
picture {
    width: 100%;
}

/* IE 11 fix for flexbox */

/* FRAME CLASS */
main>.frame {
    margin-top: $container-gap-vertical;
    margin-bottom: $container-gap-vertical;
    margin-left: 2*$container-gap-horizontal;
    margin-right: 2*$container-gap-horizontal;

    &.frame-without-container {
        margin-left: 0;
        margin-right: 0;
        width: 100vw;
        max-width: 100%;
    }

    &.frame-type-div {
        margin: 15px auto;
    }

    &.indent-horiz {
        margin: 0 auto;
    }

    &.indent-verti {
        margin: $container-gap-vertical 0;
    }

    &.indent-top {
        margin-top: $container-gap-vertical;
    }

    &.indent-bottom {
        margin-bottom: $container-gap-vertical;
    }

    &.frame-type-header {
        margin-bottom: 10px;
    }

    >header {
        margin-bottom: 20px;
    }

    @media screen and (min-width: 1921px) {
        margin-left: 2*$container-gap-horizontal-overlayout;
        margin-right: 2*$container-gap-horizontal-overlayout;
    }
}

.be-default_with_side_nav {
    @media screen and (min-width: $mobile) {
        .main > .frame:not(.without-container) {
            margin-right: 12%;
            margin-left: 2%;
            width: calc(100% - 16%);
        }
    }
}

/* -------------------------------------------------------------- */

.ce-headline-left {
    text-align: left;
}

.ce-headline-center {
    text-align: center;
}

.ce-headline-right {
    text-align: right;
}

.only-bold {
    font-weight: 700;
}

/* -------------------------------------------------------------- */
/* -------------------------------------------------------------- */

/* video responsive*/
.video {
    .video-embed {
        .video-embed-item,
        video {
            max-width: 100%;
        }
    }

    &[data-type="external"] {
        .video-embed {
            left: 0;
            overflow: hidden;
            padding-top: 56.25%;
            position: relative;
            top: 0;

            > iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 0;
            }
        }
    }
}

.ce-gallery {
    &:first-child:last-child {
        margin-bottom: 0;
    }

    &.ce-border {
        .ce-column {
            padding: 2.5vw;

            @media screen and (min-width: 1921px) {
                padding: 48px;
            }
        }
    }

    .ce-row {
        display: block;
        width: 100%;

        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
    }

    figure {
        img {
            margin: 0 auto;
        }

        figcaption {
            padding-top: 15px;
        }
    }
}

[data-ce-columns="2"] {
    .ce-column {
        max-width: 50%;
    }
}

[data-ce-columns="3"] {
    .ce-column {
        max-width: 33.33335%;
    }
}

[data-ce-columns="4"] {
    .ce-column {
        max-width: 25%;
    }
}

[data-ce-columns="5"] {
    .ce-column {
        max-width: 20%;
    }
}

[data-ce-columns="6"] {
    .ce-column {
        max-width: 16.66665%;
    }
}

@media screen and (max-width: 800px) {
    [data-ce-columns="5"] {
        .ce-column {
            max-width: 25%;
        }
    }
}

@media screen and (max-width: 700px) {
    .ce-intext {
        [data-ce-columns="4"] {
            .ce-row {
                -webkit-flex-wrap: wrap;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
            }

            .ce-column {
                max-width: 50%;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    [data-ce-columns="3"] {
        .ce-row {
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        .ce-column {
            max-width: 100%;
        }
    }

    [data-ce-columns="5"] {
        .ce-row {
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }

        .ce-column {
            max-width: 33.3333%;
        }
    }

    [data-ce-columns="6"] {
        .ce-row {
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }

        .ce-column {
            max-width: 25%;
        }
    }
}

@media screen and (max-width: 520px) {
    [data-ce-columns="2"] {
        .ce-row {
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        .ce-column {
            max-width: 100%;
        }
    }

    [data-ce-columns="4"] {
        .ce-row {
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        .ce-column {
            max-width: 100%;
        }
    }
}

@media screen and (max-width: 450px) {
    [data-ce-columns="5"] {
        .ce-column {
            max-width: 50%;
        }
    }

    [data-ce-columns="6"] {
        .ce-column {
            max-width: 50%;
        }
    }
}

.ce-textpic {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;

    .ce-gallery {
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        width: auto;
    }

    .ce-bodytext {
        -webkit-flex: 0 2 auto;
        -ms-flex: 0 2 auto;
        flex: 0 2 auto;
        width: auto;

        > header {
            display: block;
            margin-bottom: 30px;
        }
    }

    &.ce-above {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;

        .ce-gallery {
            display: block;
            margin-bottom: 40px;
            width: 100%;

            &:first-child:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.ce-below {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;

        .ce-gallery {
            display: block;
            margin-top: 40px;
            width: 100%;
        }
    }

    &.ce-center {
        .ce-gallery {
            .ce-row {
                -webkit-flex-wrap: wrap;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;

                .image {
                    height: 100%;

                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-flex-direction: column;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-justify-content: space-between;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                }
            }
        }
    }

    &.ce-right {
        &.ce-intext {
            &.ce-nowrap {
                .ce-gallery {
                    max-width: 50%;
                    width: 100%;

                    -webkit-order: 1;
                    -ms-flex-order: 1;
                    order: 1;
                }

                .ce-bodytext {
                    margin-right: 40px;

                    -webkit-order: 0;
                    -ms-flex-order: 0;
                    order: 0;
                }
            }
        }
    }

    &.ce-left {
        &.ce-intext {
            &.ce-nowrap {
                .ce-gallery {
                    max-width: 50%;
                    width: 100%;
                }

                .ce-bodytext {
                    margin-bottom: 20px;
                }
            }
        }
    }

    @media screen and (min-width: 700px) {
        &.ce-left {
            &.ce-intext {
                &.ce-nowrap {
                    .ce-bodytext {
                        margin-left: 62px;
                        margin-right: 62px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 980px) {
    .ce-textpic {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;

        &.ce-right {
            &.ce-intext {
                &.ce-nowrap {
                    .ce-gallery {
                        margin-bottom: 40px;
                        max-width: 100%;

                        -webkit-order: 0;
                        -ms-flex-order: 0;
                        order: 0;
                    }

                    .ce-bodytext {
                        -webkit-order: 1;
                        -ms-flex-order: 1;
                        order: 1;
                    }
                }
            }
        }

        &.ce-left {
            &.ce-intext {
                &.ce-nowrap .ce-gallery {
                    margin-bottom: 40px;
                    max-width: 100%;
                }
            }
        }
    }
}

.no-indent {
    .ce-left {
        .ce-bodytext {
            margin-right: $container-gap-horizontal;
        }
    }

    .ce-right {
        .ce-bodytext {
            margin-left: $container-gap-horizontal;
        }
    }
}

/* -------------------------------------------------------------- */

.frame-type-menu_pages,
.frame-type-menu_subpages,
.frame-type-menu_sitemap,
.frame-type-menu_sitemap_pages,
.frame-type-menu_abstract {
    ul {
        list-style: none;
        ul {
            margin-left: 20px;
            margin-bottom: 10px;
        }
    }
}

.frame-type-menu_abstract {
    li > p {
        margin-left: 10px;
        margin-bottom: 15px;
    }
}

/* -------------------------------------------------------------- */

.ce-uploads {
    margin-left: 20px;
}


.ce-uploads-imagegallery {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.ce-uploads-imagegallery > a {
    display: block;
    margin: 0;
    width: calc(100% / 3);
    overflow: hidden;
}

.ce-uploads-imagegallery>a figure {
    transition: all 400ms;
}

.ce-uploads-imagegallery > a:hover figure {
    transform: scale(1.1);
}

/* -------------------------------------------------------------- */

.ce-table {
    border-collapse: collapse;
    width: 100%;

    td,
    th {
        border: 1px solid #ddd;
        padding: 8px;
    }

    tr:nth-child(even){
        background-color: #f2f2f2;
    }

    tr:hover {
        background-color: #ddd;
    }

    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
    }
}

.f3-widget-paginator {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;

    margin: 10px -10px;
    width: 100%;

    li.current {
        padding: 0 10px;
    }

    a {
        padding: 10px;
    }
}
