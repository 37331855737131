/* -------------------------------- */
.news {
    .mediaelement-video {
        padding-top: 56.25%;
        overflow: hidden;

        > iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }
}

.news-list-view {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
}

/* -------------------------------- */

.news-single {
    article {
        text-align: center;

        .teaser-text {
            p {
                font-size: 1.8rem;
            }
        }
    }
}

/* -------------------------------- */

.news-list-view.news-list-slider {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 0;

    &::before {
        background-color: #000;
        content: " ";
        height: 75%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    article {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0 13px;
        overflow: hidden;

        .title,
        .date,
        a {
            color: #fff;
            font-size: 20px;
        }

        .title {
            margin-bottom: 45px;
        }

        .date {
            margin-bottom: 25px;
        }

        a {
            &:hover {
                color: $color-brand2;
            }
        }
    }

    .flex-helper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: 100%;

        .post-content {
            flex-grow: 1;
        }
    }

    .col.slider-info {
        color: #fff;
        display: none;
        flex-grow: 1;
        font-size: 28px;
        text-align: center;
    }

    .news-count {
        transform: translateY(600%);
    }

    .slider-container-outer {
        margin: 0 auto;
        max-width: 1458px;
        width: 80%;
    }

    .slider-container {
        display: flex;
        flex-direction: row;
    }

    .arrows {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }

    .arrow {
        border-color: $color-grey-dark;
        height: 20px;
        width: 20px;

        &:hover {
            border-color: $color-brand2;
        }
    }

    .arrow.left {
        margin-right: 2vw;
    }

    .arrow.right {
        margin-left: 2vw;
    }

    @media screen and (min-width: 1921px) {
        padding-right: 100px;
    }

    @media screen and (min-width: 600px) {
        padding-right: $container-gap-horizontal;

        .col.slider-info {
            display: block;
        }
    }
}

.js .news-list-view.news-list-slider {
    article {
        // cursor: pointer;

        .thumbnail {
            // &::before {
            //     background-color: rgba($color-brand2, 0.5);
            //     content: " ";
            //     height: 100%;
            //     left: 0;
            //     opacity: 0;
            //     position: absolute;
            //     top: 0;
            //     width: 100%;
            //     z-index: 50;
            //     transition: opacity 500ms;
            // }

            // &::after {
            //     color: #fff;
            //     content: "+";
            //     font-size: 60px;
            //     font-weight: 300;
            //     left: 50%;
            //     opacity: 0;
            //     position: absolute;
            //     top: 50%;
            //     z-index: 100;
            //     transform: translate(-50%, -50%);
            // }
        }

        &:hover {
            a {
                color: $color-brand2;
            }

            .thumbnail {
                &::before,
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}
