.menu-language {
    display: block;

    ul {
        display: inline-block;
    }

    li {
        display: inline-block;

        &::after {
            content: "|";
            display: inline-block;
            position: absolute;
            top: 12px;
            right: 0;
            transform: translateX(50%);
        }

        &:last-of-type::after {
            content: "";
        }
    }

    .text-muted {
        > span {
            padding: 10px;
        }
    }
}
