/**
 * MIXINS
 */

/* e.g. @include prefix(( box-shadow: 1px 1px 1px 1px opacify($color-main, 0.6) )); */
$prefixes: -webkit- -moz- -ms- -o-;
@mixin prefix($declarations) {
    @each $property, $value in $declarations {
        @each $prefix in $prefixes {
            #{$prefix + $property}: $value;
        }

        /* Output standard non-prefixed declaration */
        #{$property}: $value;
    }
}

@mixin respond-to($breakpoint){
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

/* e.g. @include box-sizing(border-box); */
@mixin box-sizing($type) {
    -webkit-box-sizing:$type;
    -moz-box-sizing:$type;
    box-sizing:$type;
}

@mixin clearfix {
    &::before,
    &::after {
        content: ''; // 1
        display: table; // 2
    }

    &::after {
        clear: both;
    }
}

// font-sizes with breakpoints
@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
    @each $fs-breakpoint, $fs-font-size in $fs-map {
        @if $fs-breakpoint == null {
            font-size: $fs-font-size;
        }
        @else {
            // If $fs-font-size is a key that exists in
            // $fs-breakpoints, use the value
            @if map-has-key($fs-breakpoints, $fs-breakpoint) {
                $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
            }
            @media screen and (min-width: $fs-breakpoint) {
                font-size: $fs-font-size;
            }
        }
    }
}
