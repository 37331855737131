form {
    font-size: 1rem;
    max-width: 960px;
    margin: 0 auto;

    vertical-align: top;
    text-align: left;

    .powermail_fieldwrap {
        margin-top: 10px;
    }

    .powermail_submit {
        cursor: pointer;
    }

    .form-group {
        display: block;
        margin-bottom: 10px;

        >label {
            display: inline-block;
            min-width: 180px;
            vertical-align: top;
            padding-top: 10px;
            width: 33%;
        }

        >div {
            display: inline;
        }
    }

    .form-check {
        margin-top: 6px;
    }

    select,
    input[type="submit"],
    input[type="email"],
    input[type="text"],
    input[type="password"],
    textarea,
    button,
    button.cr_button {
        background-color: #fff;
        border: 1px solid $color-brand;
        border-radius: 0;
        color: #000;
        font-size: 1rem;
        height: auto;
        line-height: 1.25;
        margin: 0;
        min-width: 200px;
        padding: 10px 10px;
        width: 100%;

        -webkit-appearance: none;
        appearance: none;
    }

    textarea {
        min-height: 200px;
    }

    input[type="submit"],
    button[type="submit"] {
        cursor: pointer;
        transition: background 500ms;
        width: 100%;
    }

    input[type="radio"] {
        margin-left: 2px;
        margin-right: 3px;
    }

    input[type="checkbox"] {
        margin-right: 5px;
    }

    /* FORM STYLING - SPECIFIC ELEMENTS */
    div>.frame-type-textmedia {
        margin: 0px;
        margin-bottom: 10px;
        margin-top: 5px;
    }

    /* FORM STYLING - MOBILE */
    @media screen and (max-width:480px) {
        .form-group>label {
            min-width: 100%;
        }

        select,
        input[type="submit"],
        input[type="email"],
        input[type="text"],
        textarea,
        button {
            min-width: 100%;
        }
    }
}


/* radio */
/* Example:
<label class="radio-label" for="type-{type.uid}">{type.title}
    <f:form.radio id="type-{type.uid}" name="jobTypes" value="{type.uid}" checked="{selectedJobType} == {type.uid}" />
    <span class="checkmark"></span>
</label>
*/
.main .tx-hh-ext-jobsdahoam form,
.tx-hh-ext-jobsdahoam form {
    .radio-container {
        display: flex;
    }

    .radio-label {
        cursor: pointer;
        display: block;
        float: none;
        font-size: inherit;
        height: auto;
        margin-bottom: 12px;
        margin-top: 0;
        padding-left: 35px;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
            cursor: pointer;
            position: absolute;
            opacity: 0;
        }

        .checkmark {
            background-color: transparent;
            border: 1px solid $color-brand2;
            height: 19px;
            left: 0;
            position: absolute;
            top: 0;
            width: 19px;
            transition: all 400ms;
        }

        &:hover {
            input ~ .checkmark {
                background-color: $color-brand;
            }
        }

        input:checked ~ .checkmark {
            background-color: $color-brand2;
        }

        input:checked ~ .checkmark:after {
            display: block;
        }
    }
}

/* EXT: hh_powermail_checkboxlink */
.powermail_fieldwrap_checkboxlink {
    .powermail_field {
        display: flex;

        input {
            margin-top: 5px;
        }
    }

    .checkbox-description {
        font-size: inherit;

        p {
            font-size: inherit;
        }
    }
}
