$desktopBreakpoint: 961px;
$hdY: 1080px;
$hd3kY: 1440px;
$hd4kY: 2160px;

.frame-layout-galleryDiagonal {
    // Headline / Bodytext Animation - Start & Defaults
    .headline {
        & > h2, & > h3, & ~ .ce-textpic .ce-bodytext {
            transition-property:all, opacity;
            transition-duration:1500ms, 500ms;
            transition-delay:1000ms;
            transition-timing-function:cubic-bezier(.68,-0.55,.27,1.55);

            opacity:0;
            transform:translateX(50%);
        }

        & > h2 {
            transition-duration:1600ms, 500ms;
            transition-delay:925ms;
        }
    }

    .ce-bodytext {
        @media screen and (min-width:$desktopBreakpoint){
            width:75%;
        }
    }

    // Headline Animation - End
    &.io--observed .headline h2, &.io--observed .headline h3, &.io--observed .headline ~ .ce-textpic .ce-bodytext,
    .headline.io--observed h2, .headline.io--observed h3, .headline.io--observed ~ .ce-textpic .ce-bodytext {
        opacity:1; transform:translateX(0%);
    }

    // Textpic Styling
    .ce-textpic {
        display:block;
    }

    // Gallery Styling & Animations
    .ce-gallery {
        height:auto;
        padding:10vmin 0 12vmin 0;
        background:#F6F6F6;
        width:100vw !important;
        max-width:1920px;
        transform:translateX(-12vw);

        @media screen and (min-width:1921px){ // Needed due to weird fluid-styled-content 1921px media query
            transform:none;
            width:1920px !important;
            margin-left:-$container-gap-horizontal-overlayout*2;

            .ce-gallery-inner { width:calc(100% - (#{$container-gap-horizontal-overlayout}*4)) !important; }
        }

        @media screen and (min-width:$desktopBreakpoint){
            height:80vmin;
            padding:15vmin 0;
        }

        @media screen and (min-height:$hdY){

        }

        @media screen and (min-height:$hd3kY){
            height:60vmin;
        }

        @media screen and (min-height:$hd4kY){
            height:50vmin;
        }

        // Reset
        * { margin:0; padding:0; }

        // Global Gallery Styling
        .ce-gallery-inner { width:calc(100% - 24vw); height:100%; position:relative; top:0; left:0; margin:0 auto; }
        .ce-column { width:100%; height:100%; position:absolute; left:0; top:0; }
        .io--active.ce-column { position:relative; z-index:1122; }

        @media screen and (min-width:$desktopBreakpoint){
            .io--active.ce-column { position:absolute; }
            .io--active-0.ce-column { z-index:1111; }
            .io--active-1.ce-column { z-index:1222; pointer-events:none; }
            .io--active-2.ce-column { z-index:1111; pointer-events:none; }
        }

        // Gallery Controls
        .gallery-controls { position:absolute; right:50%; transform:translateX(50%); bottom:-9vmin; z-index:1111; }
        .gallery-controls-btn { display:inline-block; width:14px; transition:border-color .5s; height:14px; border:2px solid #64666B; border-left:0; border-bottom:0; cursor:pointer; }
        .gallery-controls-btn:hover { border-color:$color-brand2; }
        .gallery-controls-btn-prev { transform:rotate(-135deg); margin-right:20px; }
        .gallery-controls-btn-next { transform:rotate(45deg); }

        @media screen and (min-width:$desktopBreakpoint){
            .gallery-controls { right:0; transform:translateX(0%); }
        }

        // Gallery Pagination
        .slider-pagination { color:$color-brand2; font-weight:600; }

        // Image - Default Styling
        .image { height:100%; display:flex; flex-direction:column-reverse; justify-content:space-between; }
        .image-container { max-width:100%; width:100%; height:auto; display:block; opacity:0; transition:all 2s, opacity 1s; }
        .image-container a { display:block; width:100%; height:100%; }
        .image-container picture { display:block; width:100%; height:100%; }
        .image-container img { width:100%; height:auto; object-fit:cover; object-position:center center; }
        .image-caption { width:100%; margin-bottom:20px; }

        @media screen and (min-width:$desktopBreakpoint){
            .image { flex-direction:row-reverse; }
            .image-container { max-width:50%; width:auto; height:50vmin; }
            .image-container img { width:auto; height:100%; }
            .image-caption { max-width:50%; margin-bottom:0; }
        }

        @media screen and (min-height:$hdY){
            .image-container { height:50vmin; max-width:none; }
        }

        @media screen and (min-height:$hd3kY){
            .image-container { height:30vmin; }
        }

        @media screen and (min-height:$hd4kY){
            .image-container { height:20vmin; }
        }

        // Image - Animation Start (3-Col Image Animation)
        @media screen and (min-width:$desktopBreakpoint){
            .io--element .image-container {  }
            .io--element-0 .image-container, .ce-column-1 .image-container { transform-origin:left bottom; transform:scale(0.01) translate(-40vmin, 40vmin); }
            .io--element-1 .image-container, .ce-column-2 .image-container { transform-origin:center center; transform:scale(0.01); }
            .io--element-2 .image-container, .ce-column-3 .image-container { transform-origin:right top; transform:scale(0.01) translate(40vmin, -40vmin); }
        }

        // Image - Animation End (3-Col Image Animation)
        &.io--observed {
            .io--active .image-container { opacity:1; }

            @media screen and (min-width:$desktopBreakpoint){
                .io--active-0 .image-container { transform:scale(.5) translate(-15vmin, 15vmin); z-index:444; }
                .io--active-1 .image-container { transform:scale(1); z-index:500; }
                .io--active-2 .image-container { transform:scale(.5) translate(15vmin, -15vmin); z-index:444; }
            }
        }

        // Image - Caption Defaults & Animation
        .image-caption {
            // Image - Caption Animation Start
            > * {
                transition:all 1250ms, opacity 750ms;
                transition-delay:1250ms;
                transition-timing-function:cubic-bezier(.29,.71,.47,1.39);

                transform:translateX(-50px);
                opacity:0;
                display:block;
            }

            p {
                line-height:2;

                @media screen and (min-width:$desktopBreakpoint){
                    line-height:2;
                }
            }

            p:first-of-type {
                font-family: 'PT Serif', serif;
                font-size:32px;
                transform:translateX(-75px);

                @media screen and (min-width:$desktopBreakpoint){
                    font-size:48px;
                }
                @media screen and (min-width:1920px){
                    font-size:96px;
                }
            }

            p:first-of-type ~ p {
                font-size:18px; font-weight:bold;

                @media screen and (min-width:1920px){
                    font-size:27px;
                }
            }

            p:first-of-type ~ * {
                transform:translateX(-50px);
            }

            span.image-caption-link, div.image-caption-link, a.image-caption-link {
                transform:translateX(-75px);
                margin-top:3vmin;
                padding-bottom:3vmin;

                &, a {
                    text-transform:uppercase;
                    font-size:16px;
                    color:$text-color;
                }

                @media screen and (min-width:$desktopBreakpoint){
                    margin-top:10vmin;
                    text-align:left;
                }
                @media screen and (min-width:1920px){
                    font-size:18px;
                }
            }

        }

        // Image - Caption Animation End
        &.io--observed .io--active-0 .image-caption > * {
            transform:translateX(0%);
            opacity:1;
        }
    }
}
