#footer {
    background: $footer-bg;
    color: $footer-color-text;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;

    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    padding: 30px 30px;

    >.frame,
    >.menu-social {
        display: block;
    }

    >.menu-social {
        margin-top: 40px;
    }

    >.menu-social,
    >.menu-language,
    >.menu-additonaltopmenu,
    >.menu-trenner {
        li {
            opacity: 0.5;
            transition: opacity 400ms;

            &:hover {
                opacity: 1
            }
        }
    }

    a {
        color: $footer-color-a;

        &:hover,
        .act {
            color: $footer-color-a-hover;
        }
    }

    p {
        font-size: 18px;
    }

    >.frame-type-text {
        ul {
            list-style: none;
            display: flex;

            li {
                &:not(:last-child) {
                    &:after {
                        content: "|";
                        margin: 0 10px;
                        position: relative;
                    }
                }
            }
        }
    }

    .menu {
        >ul {
            margin-left: -10px;
            margin-right: -10px;

            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            >li {
                padding: 10px;
            }
        }
    }

    .menu.menu-language {
        >ul {
            >li {
                padding: 15px;
            }
        }
    }

    .menu-trenner {
        font-size: 14px;
        margin-top: 20px;

        ul {
            li {
                padding: 0;
                text-align: center;
            }
        }

        a {
            display: inline-block;
            padding: 5px 10px;
        }
    }

    .menu-social + .menu-language,
    .menu-social + .menu-additonaltopmenu {
        margin-top: 25px;
    }

    @media screen and (min-width: 600px) {
        font-size: 1.375rem;

        p {
            font-size: 1em;
        }

        .menu-trenner {
            font-size: 1rem;

            ul {
                li {
                    padding: 0.8vw;
                }

                a {
                    padding: 10px;
                }
            }
        }
    }

    @media screen and (max-width: 800px) {
        >.frame-type-text {
            margin-bottom: 20px;
            width: 100%;

            ul {
                flex-direction: column;
                text-align: center;

                li::after {
                    display: none;
                }
            }
        }

        .menu {
            width: 100%;

            >ul {
                justify-content: center;
            }
        }
    }
}

#banner+#footer {
    margin-top: 0;
}
