.accordion {
    + .accordion {
        margin-top: 4rem;
    }
    .acc-header {
        cursor: pointer;
        padding: 1rem 0 1rem;
        position: relative;

        .toggle {
            display: block;
            height: 20px;
            position: absolute;
            right: -35px;
            top: 20px;
            width: 30px;
            &:before,
            &:after {
                background-color: $text-color;
                bottom: 0;
                content: "";
                display: block;
                height: 4px;
                left: 50%;
                position: absolute;
                transition: bottom .1s ease-out, transform .1s ease-out;
                transform: rotate(-135deg);
                transform-origin: 0 50%;
                width: 70%;
            }
            &:after {
                transform: rotate(-45deg);
                margin-left: -2px;
            }
        }

        @media screen and (min-width: 860px) {
            padding: 1rem 120px 1rem 0;

            .toggle {
                height: 3.5vw;
                top: 1rem;
                width: 5vw;
            }
        }
        @media screen and (min-width: 1921px) {
            .toggle {
                height: 60px;
                width: 100px;
            }
        }
    }

    .acc-content {
        display: none;

        h3 {
            @include font-size($h4-font-sizes);
            margin: 2rem 0 1rem;
        }

        .frame-type-text,
        p,
        ul {
            max-width: 1000px;
        }

        .frame-type-text {
            margin-top: 2rem;
        }
    }

    &.opened {
        .acc-header {
            .toggle {
                &:before {
                    bottom: 80%;
                    transform: rotate(-225deg);
                }
                &:after {
                    bottom: 80%;
                    transform: rotate(45deg);
                }
            }
        }
    }
}
