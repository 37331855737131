.tx-felogin-pi1 {
    .login {
        margin: auto;
        width: 360px;

        form {
            background: #FFFFFF;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
            max-width: 360px;
            margin: 0 auto;
            padding: 45px;
            position: relative;
            text-align: center;
            z-index: 1;

            input {
                background: #f2f2f2;
                border: 0;
                box-sizing: border-box;
                font-family: "Roboto", sans-serif;
                font-size: 14px;
                margin: 0 0 15px;
                outline: 0;
                padding: 15px;
                width: 100%;
            }

            button {
                background: #4CAF50;
                border: 0;
                color: #FFFFFF;
                cursor: pointer;
                font-family: "Roboto", sans-serif;
                font-size: 14px;
                outline: 0;
                padding: 15px;
                text-transform: uppercase;
                width: 100%;
                transition: all 0.3 ease;
            }

            button:hover,
            button:active,
            button:focus {
                background: #43A047;
            }

            input[type="submit"],
            button[type="submit"] {
                &:hover {
                    background: darken(#f2f2f2, 20);
                }
            }

            .message {
                color: #b3b3b3;
                font-size: 12px;
                margin: 15px 0 0;

                a {
                    color: #4CAF50;
                    text-decoration: none;
                }
            }

            .register-form {
                display: none;
            }
        }

        .close {
            cursor: pointer;
            height: 32px;
            opacity: 0.3;
            position: absolute;
            right: 14px;
            top: 14px;
            width: 32px;
            z-index: 900;

            &:hover {
                opacity: 1;
            }

            &:after,
            &:before {
                background-color: #333;
                content: ' ';
                height: 33px;
                left: 15px;
                position: absolute;
                width: 2px;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}

header {
    .fe-user {
        .tab-label {
            cursor: pointer;
        }

        .tab-content {
            background-color: #fff;
            bottom: -60px;
            height: 60px;
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            padding: 10px;
            position: absolute;
            right: 0;
            transition: all .35s;
        }

        .dummy-image {
            border-radius: 100%;
            overflow: hidden;
            width: 40px;
        }

        #feLogin {
            display: none;

            &:checked {
                ~ .tab-content {
                    max-height: 100vh;
                    opacity: 1;
                }
            }
        }

        &.logged-in {
            .user-image {
                + .user-name {
                    display: none;
                }
            }
        }

        &.logged-out {
            .tab-content {
                bottom: -200px;
                height: 196px;
            }
        }
    }
}

.main {
    .tx-felogin-pi1 {
        .login {
            .close {
                display: none;
            }

            form {
                box-shadow: none;
                position: relative;
            }
        }
    }
}

#center-page {
    > .frame-type-login {
        left: 50%;
        position: fixed;
        top: 0;
        transform: translateX(-50%);
        z-index: 800;
    }
}
