.ionav-btn {}

.custom-ionav-btn {
    height: 76px;
    left: 50%;
    position: fixed;
    right: auto;
    top: 46px;
    width: 50px;
    z-index: 860;

    transform: translateX(-45vw);

    &::before,
    &::after { display: none; }

    .bar {
        height: 2px;
        width: 100%;
        display: block;
        margin: 7px auto;
        position: relative;
        background-color: #fff;
        -moz-border-radius: 10px;
        -webkit-border-radius: 10px;
        border-radius: 10px;
        -moz-transition: 0.4s;
        -o-transition: 0.4s;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
    .bar {
        position: absolute;
    }

    .bar:nth-of-type(1) {
        top: 15px;
        -moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s;
        -o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s;
        -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
        -webkit-transition-delay: 0.3s, 0.1s;
        transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
        -moz-animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
        -webkit-animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
        animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
    }

    .bar:nth-of-type(2) {
        top: 30px;
        -moz-transition: ease 0.3s 0.3s;
        -o-transition: ease 0.3s 0.3s;
        -webkit-transition: ease 0.3s;
        -webkit-transition-delay: 0.3s;
        transition: ease 0.3s 0.3s;
        -moz-animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
        -webkit-animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
        animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
    }

    .bar:nth-of-type(3) {
        top: 45px;
        -moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s;
        -o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s;
        -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
        -webkit-transition-delay: 0.3s, 0.1s;
        transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
        -moz-animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
        -webkit-animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
        animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
    }

    @media screen and (min-width: 600px) {
        top: 7.5vh;
    }

    @media screen and (min-width: 1921px) {
        transform: translateX(-870px);
    }
}

.ionav--open {
    .bar:nth-of-type(1) {
        top: 30px;
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        -moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s;
        -o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s;
        -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
        -webkit-transition-delay: 0.1s, 0.5s;
        transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
    }

    .bar:nth-of-type(2) {
        opacity: 0;
    }

    .bar:nth-of-type(3) {
        top: 30px;
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s;
        -o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s;
        -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
        -webkit-transition-delay: 0.1s, 0.5s;
        transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
    }
}

@keyframes animateOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.ionav-overlay {
    background: transparent;
    align-items: flex-end;
    height: 100vh;
    width: 100vw;
}

.ionav-canvas {
    background: transparent;
    max-width: 1920px;
    margin: 0 auto;
    min-height: auto;
    min-width: auto;
    opacity: 0;
    padding-left: 12vw;
    padding-bottom: 10vh;
    width: 100%;

    >* {
        background: transparent;
    }

    @media screen and (min-width: 1921px) {
        padding-left: 230px;
        padding-bottom: 108px;
    }
}

#ionav-menu-main {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    width: 85%;

    > ul {
        order: 2;

        > li {
            > a {
                color: rgba(255,255,255, 0.6);
                display: inline-block;
                font-size: 31px;
                font-weight: 600;
                line-height: 1;
                position: relative;

                @media screen and (min-width: 600px) {
                    font-size: 5vw;
                }
            }

            &:hover > a,
            > a:hover,
            &.active > a,
            > a.active {
                color: #fff;
            }

            &.ionav--active {
                > a {
                    &::after {
                        background-image: url("../Icons/icon_plus_blue.svg");
                        background-size: contain;
                        background-repeat: no-repeat;
                        content: " ";
                        display: block;
                        height: 0.5em;
                        position: absolute;
                        right: -0.8em;
                        top: 0;
                        width: 0.5em;
                    }
                }
            }
        }
    }

    >.activemenu {
        display: none;
        order: 1;

        >ul {
            display: flex;
        }

        li {
            opacity: 0;
        }

        a {
            color: #fff;
            font-weight: 600;
        }
    }

    @media screen and (min-width: 600px) {
        width: 100%;

        >.activemenu {
            display: block;
        }
    }

    @media screen and (min-width: 1921px) {
        >ul {
            >li {
                >a {
                    font-size: 96px;
                }
            }
        }
    }
}



.ionav--open {
    .ionav-overlay {
        display: flex;
    }

    .ionav-canvas {
        animation-name: animateOpacity;
        animation-duration: 4s;
        animation-fill-mode: forwards;
    }

    #ionav-menu-main {
        >.activemenu {
            li {
                animation-name: animateOpacity;
                animation-duration: 1s;
                animation-fill-mode: forwards;

                &:nth-child(2) {
                    animation-delay: 200ms;
                }

                &:nth-child(3) {
                    animation-delay: 400ms;
                }

                &:nth-child(4) {
                    animation-delay: 600ms;
                }

                &:nth-child(5) {
                    animation-delay: 800ms;
                }

                &:nth-child(6) {
                    animation-delay: 1000ms;
                }

                &:nth-child(7) {
                    animation-delay: 1200ms;
                }

                &:nth-child(8) {
                    animation-delay: 1400ms;
                }

                &:nth-child(9) {
                    animation-delay: 1600ms;
                }

                &:nth-child(10) {
                    animation-delay: 1800ms;
                }
            }

            a {
                &:hover,
                &.act {
                    color: $color-brand2;
                }
            }
        }
    }

    #header {

        #menu-main,
        .top-box,
        .scroll {
            opacity: 0;
        }
    }
}

#ionav-menu-main {
    > ul {
        > li {
            > ul {
                font-weight: 600;
                padding-bottom: 50px;
                padding-left: 4px;

                a {
                    padding: 6px;
                }

                .active {
                    > a {
                        color: $color-brand2;
                    }
                }
            }
        }
    }
}

.ionav--hasSubnav {
    > ul {
        a {
            &:hover,
            .act {
                color: $color-brand2;
            }
        }
    }
}

@media screen and (min-width: 600px) {
    ul.ionav--hasSubnav.ionav--active {
        display: none;
    }
}

#header {

    #menu-main,
    .top-box,
    .scroll {
        opacity: 1;
        transition: opacity 500ms;
    }
}
