/* pt-serif-regular - latin */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  src: url('../Fonts/pt-serif-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('PT Serif'), local('PTSerif-Regular'),
       url('../Fonts/pt-serif-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../Fonts/pt-serif-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../Fonts/pt-serif-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../Fonts/pt-serif-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../Fonts/pt-serif-v11-latin-regular.svg#PTSerif') format('svg'); /* Legacy iOS */
}

/* montserrat-200 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    src: url('../Fonts/Montserrat/montserrat-v14-latin-200.eot');
    /* IE9 Compat Modes */
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
        url('../Fonts/Montserrat/montserrat-v14-latin-200.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../Fonts/Montserrat/montserrat-v14-latin-200.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../Fonts/Montserrat/montserrat-v14-latin-200.woff') format('woff'),
        /* Modern Browsers */
        url('../Fonts/Montserrat/montserrat-v14-latin-200.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../Fonts/Montserrat/montserrat-v14-latin-200.svg#Montserrat') format('svg');
    /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../Fonts/Montserrat/montserrat-v14-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('../Fonts/Montserrat/montserrat-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../Fonts/Montserrat/montserrat-v14-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../Fonts/Montserrat/montserrat-v14-latin-regular.woff') format('woff'),
        /* Modern Browsers */
        url('../Fonts/Montserrat/montserrat-v14-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../Fonts/Montserrat/montserrat-v14-latin-regular.svg#Montserrat') format('svg');
    /* Legacy iOS */
}

/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../Fonts/Montserrat/montserrat-v14-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
       url('../Fonts/Montserrat/montserrat-v14-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../Fonts/Montserrat/montserrat-v14-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../Fonts/Montserrat/montserrat-v14-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../Fonts/Montserrat/montserrat-v14-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../Fonts/Montserrat/montserrat-v14-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../Fonts/Montserrat/montserrat-v14-latin-700.eot');
    /* IE9 Compat Modes */
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url('../Fonts/Montserrat/montserrat-v14-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../Fonts/Montserrat/montserrat-v14-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../Fonts/Montserrat/montserrat-v14-latin-700.woff') format('woff'),
        /* Modern Browsers */
        url('../Fonts/Montserrat/montserrat-v14-latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../Fonts/Montserrat/montserrat-v14-latin-700.svg#Montserrat') format('svg');
    /* Legacy iOS */
}

@font-face {
    font-family: 'fontello';
    src: url('../Fonts/fontello.eot?6984843');
    src: url('../Fonts/fontello.eot?6984843#iefix') format('embedded-opentype'),
        url('../Fonts/fontello.woff?6984843') format('woff'),
        url('../Fonts/fontello.ttf?6984843') format('truetype'),
        url('../Fonts/fontello.svg?6984843#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.mail:before,
[class^="icon-"]:before,
[class*=" icon-"]:before,
[class^="fa-"]:before,
[class*=" fa-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .1em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-mail:before,
.mail:before,
.icon-mail-1:before {
    content: '\e803';
}

.icon-at:before,
.fa-at:before {
    content: '\e800';
    margin-top: -3px;
}

.icon-at:before {
    content: '\0040';
    font-family: 'Roboto', sans-serif;
    margin: 0;
}

.icon-gplus:before,
.fa-google-plus:before {
    content: '\e801';
}

.icon-twitter:before,
.fa-twitter:before {
    content: '\f099';
}

.icon-facebook:before,
.fa-facebook:before {
    content: '\f09a';
}

.icon-angle-up:before,
.fa-angle-up:before {
    content: '\f106';
}

.icon-angle-down:before,
.fa-angle-down:before {
    content: '\f107';
}

.icon-copyright:before,
.fa-copyrightat:before {
    content: '\f1f9';
}

.icon-whatsapp:before,
.fa-whatsapp:before {
    content: '\f232';
}
