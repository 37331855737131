.menu-tab {
    position:relative; z-index:123;

    * { margin:0; padding:0; }
    ul { list-style-type:none; margin:0 !important; padding:0; }
    ul li { display:inline-block; margin-right:4px; }
    ul li a {
        display:inline-block;
        padding:12px 16px;
        background:#fff;
        border:1px solid transparent; border-bottom:1px solid $color-brand2;
        // border:1px solid #e0e0e0; border-bottom:1px solid $color-brand2;
        color:$text-color; font-weight:400;
        opacity:.55;
    }
    ul li a:hover, ul li a.active { border:1px solid $color-brand2; border-bottom:1px solid #fff; opacity:1; }

    & + div, & + .ext-content, & + .ext-form {
        max-width:100%;
        margin-top:-1px;
        padding:32px;
        border:1px solid $color-brand2;
    }
}
