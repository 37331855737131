/* header */
h1, h2, h3, h4, h5 {
    > span {
        // clearfix https://css-tricks.com/snippets/css/clear-fix/
        &:not(:last-child)::after {
            content: " ";
            display: table;
            clear: both;
        }
    }
}

.image > a {
    &::after {
        color: #fff;
        content: "+";
        font-size: 4rem;
        font-weight: 200;
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 400ms;
    }

    &:hover {
        &::after {
            opacity: 1;
        }
    }
}

/* ViewHelper cropAcc */
// .btn-acc {
//     display: none;

//     + .part-more {
//         display: inline-block;
//     }
// }

// .js .btn-acc {
//     display: inline-block;

//     + .part-more {
//         display: none;
//     }
// }

/* Custom Buttons */
.additional-btns {
    .btn {
        background-repeat: no-repeat;
        background-position: center;
        background-size: 70%;
        border: 1px solid;
        border-color: $color-brand2;
        border-radius: 100%;
        color: transparent;
        display: inline-block;
        height: 50px;
        width: 50px;
    }

    /* https://codepen.io/elliz/full/ygvgay */
    .btn-external {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='43.78px' height='27.202px' viewBox='0 0 43.78 27.202' enable-background='new 0 0 43.78 27.202' xml:space='preserve'%3e%3cg%3e%3cpath fill='%231F407A' d='M22.616,27.202V2.327c0,0,8.008-5.236,21.164,0v24.875C43.78,27.202,35.2,22.62,22.616,27.202'/%3e%3cpath fill='%231F407A' d='M21.164,27.202V2.327c0,0-8.008-5.236-21.164,0v24.875C0,27.202,8.58,22.62,21.164,27.202'/%3e%3c/g%3e%3c/svg%3e ");
    }

    .btn-download {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='35.15px' height='37.47px' viewBox='0 0 35.15 37.47' enable-background='new 0 0 35.15 37.47' xml:space='preserve'%3e%3crect x='9.867' fill='%231F407A' width='15.417' height='2.453'/%3e%3crect x='9.867' y='3.503' fill='%231F407A' width='15.417' height='3.928'/%3e%3cpolygon fill='%231F407A' points='25.284,23.903 25.284,8.482 9.867,8.482 9.867,23.903 0,23.903 17.573,37.47 17.578,37.47 35.15,23.903 '/%3e%3c/svg%3e");
    }
}

.image-caption .additional-btns,
figcaption .additional-btns {
    margin-top: 15px;
}

.downloadbtns {
    .ce-gallery {
        .ce-column {
            padding-left: 30px;
            padding-right: 30px;
        }

        .ce-row {
            padding-bottom: 60px;

            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}

/* col-2-images-text-top */
.col-2-images-text-top,
.col-2-images-small-text-top {
    .row {
        width: 100%;

        > .col {
            width: 100%;

            &.text {
                *:not(header) {
                    max-width: 1040px;
                }
            }

            img  {
                width: 100%;
            }
        }

        &:nth-child(2) {
            margin-top: -2vw;
        }
    }

    header {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 20px;

        h1,h2,h3,h4,h5 {
            a {
                color: inherit;

                &:hover {
                    color: inherit;
                }
            }
        }

        .subheader {
            padding-bottom: 4px;
        }
    }

    .text {
        padding: 50px 0;
    }

    .icon-bubble {
        background-image: url("../Icons/icon_bubble_plus_links_oben.svg");
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        height: 40px;
        margin-left: 3.5vw;
        margin-right: auto;
        margin-bottom: 20px;
        margin-top: 20px;
        width: 40px;
    }

    @media screen and (min-width: 600px) {
        .icon-bubble {
            height: 62px;
            margin-left: auto;
            width: 62px;
        }
    }

    @media screen and (min-width: 1024px) {
        .row {
            width: 100%;

            > .col:not(.text) {
                flex-grow: 1;
            }

            &:last-child {
                > .col {
                    &:last-child {
                        margin-top: -6%;
                    }
                }
            }

            &:nth-child(2) {
                margin-top: 6%;
                flex-wrap: nowrap;
            }
        }

        .icon-bubble {
            left: 25px;
            margin: 0;
            top: 25px;
        }
    }

    @media screen and (min-width: 1921px) {
        &.frame-without-container {
            .text {
                padding-left: 2*$container-gap-horizontal-overlayout;
                padding-right: 2*$container-gap-horizontal-overlayout;
            }
        }
    }
}

.col-2-images-small-text-top {
    @media screen and (min-width: 1024px) {
        > .row {
            > .col {
                &.text {
                    padding-left: 20vw;
                }
            }
        }

        .image-left {
            flex-basis: 1200px;
        }

        .image-right {
            flex-basis: 600px;
        }
    }

    @media screen and (min-width: 1921px) {
        > .row {
            > .col {
                &.text {
                    padding-left: 384px;
                }
            }
        }
    }
}

.frame-without-container {
    .col-2-images-text-top,
    .col-2-images-small-text-top {
        .text {
            padding-left: 2*$container-gap-horizontal;
            padding-right: 2*$container-gap-horizontal;
        }

        @media screen and (min-width: 1921px) {
            .text {
                padding-left: 2*$container-gap-horizontal-overlayout;
                padding-right: 2*$container-gap-horizontal-overlayout;
            }
        }
    }
}
/* END: col-2-images-text-top */

/* col-2-images-text-top-right */
.col-2-images-text-top-right,
.col-2-images-small-text-top-right {
    .row {
        width: 100%;

        > .col {
            width: 100%;

            img  {
                width: 100%;
            }
        }
    }

    header {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 20px;

        h1,h2,h3,h4,h5 {
            a {
                color: inherit;

                &:hover {
                    color: inherit;
                }
            }
        }

        .subheader {
            padding-bottom: 4px;
        }
    }

    .text {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .icon-bubble {
        background-image: url("../Icons/icon_bubble_plus_rechts_oben.svg");
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        height: 40px;
        margin-left: 3.5vw;
        margin-right: auto;
        margin-bottom: 20px;
        margin-top: 20px;
        width: 40px;
    }

    @media screen and (min-width: 600px) {
        .icon-bubble {
            height: 62px;
            margin-left: auto;
            width: 62px;
        }
    }

    @media screen and (min-width: 1024px) {
        .text {
            padding: 50px 3.5vw;
        }

        .row {
            width: 100%;

            &:nth-child(2) {
                flex-wrap: nowrap;
            }

            > .col {
                &:first-child {
                    width: 41%;
                }

                &:last-child {
                    width: 59%;
                }

                &.text {
                    width: 59%
                }
            }

            &:last-child {
                > .col {
                    &:first-child {
                        margin-top: -6%;
                    }
                }
            }
        }

        .icon-bubble {
            left: calc(100% - 62px - 25px);
            margin: 0;
            top: 25px;
        }
    }

    @media screen and (min-width: 1921px) {
        .text {
            padding: 50px 68px;
        }
    }
}

.col-2-images-small-text-top-right {
    @media screen and (min-width: 1024px) {
        .row {
            flex-wrap: nowrap;

            .col {
                &:nth-child(1) {
                    flex-basis: 600px;
                }

                &:nth-child(2) {
                    flex-basis: 1200px;
                }
            }
        }
    }
}

.frame-without-container {
    .col-2-images-text-top-right,
    .col-2-images-small-text-top-right {
        .text {
            margin-left: 2*$container-gap-horizontal;
            margin-right: 2*$container-gap-horizontal;
            width: calc(100% - 4*$container-gap-horizontal);
        }

        @media screen and (min-width: 1024px) {
            .text {
                margin-left: 0;
                margin-right: 0;
                width: 100%;
            }

            .row {
                > .col {
                    &.text {
                        width: 55%;
                    }
                }
            }
        }
    }
}
/* END: col-2-images-text-top-right */

/* START: col-2-images-equalheight-bottombar-text-top */
.main > .frame-layout-col-2-images-equalheight-bottombar-text-top,
.frame-layout-col-2-images-equalheight-bottombar-text-top {
    margin-left: 0;
    margin-right: 0;
}

.col-2-images-equalheight-bottombar-text-top {
    > .row {
        > .col {
            header {
                display: flex;
                flex-direction: column-reverse;

                h1,h2,h3,h4,h5 {
                    a {
                        color: inherit;

                        &:hover {
                            color: inherit;
                        }
                    }
                }

                .subheader {
                    padding-bottom: 20px;
                }
            }

            &.text {
                padding: 50px 2*$container-gap-horizontal;

                .text-content {
                    padding-top: 25px;
                }
            }
        }

        &:nth-child(2) {
            flex-wrap: nowrap;
            justify-content: flex-end;
            margin-left: 6.2vw;

            > .col {
                img {
                    width: 100%;
                }
            }
        }
    }

    .bar {
        display: flex;
        align-items: flex-end;

        background-color: #000;
        min-height: 12vw;
        margin-left: 2*$container-gap-horizontal;

        .icon-bubble {
            background-image: url("../Icons/icon_bubble_plus_links_unten_white.svg");
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            height: 40px;
            margin-left: 25px;
            margin-bottom: 20px;
            margin-top: 20px;
            width: 40px;
        }
    }

    @media screen and (min-width: 600px) {
        .icon-bubble {
            height: 62px;
            width: 62px;
        }
    }

    @media screen and (min-width: 1024px) {
        > .row {
            > .col {
                &.text {
                    .text-content {
                        padding-left: 20vw;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1921px) {
        > .row {
            &:nth-child(2) {
                margin-left: 90px;
            }

            > .col {
                &.text {
                    padding-left: 2*$container-gap-horizontal-overlayout;
                    padding-right: 2*$container-gap-horizontal-overlayout;

                    .text-content {
                        padding-left: 384px;
                    }
                }
            }
        }

        .bar {
            .icon-bubble {
                min-height: 2*$container-gap-horizontal-overlayout;
            }
        }
    }
}
/* END: col-2-images-equalheight-bottombar-text-top */

/* START: career */
.frame-layout-career,
main > .frame-layout-career {
    margin-left: $container-gap-horizontal;
    margin-right: $container-gap-horizontal;

    @media screen and (min-width: 1921px) {
        margin-left: $container-gap-horizontal-overlayout;
        margin-right: $container-gap-horizontal-overlayout;
    }
}

.career {
    header {
        display: flex;
        flex-direction: column-reverse;

        .subheader {
            padding-bottom: 4px;
        }
    }

    .images {
        width: 61%;

        .row {
            align-items: flex-start;

            &:first-child {
                img {
                    &:nth-child(1) {
                        width: 36.9%;
                    }
                    &:nth-child(2) {
                        margin-left: 2.5vw;
                        margin-top: 1.5vw;
                        width: 40.8%;
                    }
                }
            }

            &:last-child {
                margin-top: -5vw;

                img {
                    &:nth-child(1) {
                        margin-left: 7vw;
                        margin-top: 1vw;
                        width: 33.5%;
                        z-index: 50;
                    }
                    &:nth-child(2) {
                        margin-left: -1vw;
                        margin-top: 5vw;
                        width: 23.8%;
                        z-index: 100;
                    }
                    &:nth-child(3) {
                        margin-left: -2.5vw;
                        width: 30.1%;
                        z-index: 50;
                    }
                }
            }
        }
    }

    .text {
        background-color: #000;
        color: #fff;
        padding: 30px;

        h1, h2, h3, h4, h5, h6,
        h1.subheader, h2.subheader, h3.subheader, h4.subheader, h5.subheader, h6.subheader {
            color: #fff;
        }

        a {
            color: #fff;

            &:hover {
                color: $color-brand2;
            }
        }

        .btn-with-line a {
            &::after {
                background-color: #fff;
            }

            &:hover {
                &::after {
                    background-color: $color-brand2;
                }
            }
        }
    }

    .images + .text {
        width: 39%;
    }

    @media screen and (max-width: 1270px) {
        .images {
            width: 100%;
        }

        .text,
        .images + .text {
            width: 100%;
        }

        .images + .text {
            margin-top: 40px;
        }
    }

    @media screen and (min-width: 600px) {
        .text {
            padding: 3vw;
        }
    }

    @media screen and (min-width: 1921px) {
        .images {
            .row {
                &:first-child {
                    img {
                        &:nth-child(1) {

                        }
                        &:nth-child(2) {
                            margin-left: 48px;
                            margin-top: 29px;
                        }
                    }
                }

                &:last-child {
                    margin-top: -96px;

                    img {
                        &:nth-child(1) {
                            margin-left: 134.5px;
                            margin-top: 19px;
                        }
                        &:nth-child(2) {
                            margin-left: -19px;
                            margin-top: 96px;
                        }
                        &:nth-child(3) {
                            margin-left: -48px;
                        }
                    }
                }
            }
        }

        .text {
            padding: 57px;
        }
    }
}
/* END: career */

/* START: imagegrid-* */

[class*=frame-layout-imagegrid-],
main > [class*=frame-layout-imagegrid-] {
    display: flex;
    flex-direction: column;
    margin: 0;

    .col-6 {
        width: 100%;
    }

    .col-12 {
        width: 100%;
    }

    [class*=col-] > .flex-ie-helper {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        height: 100%;
        width: 100%;

        > .row {
            flex-grow: 1;
        }
    }

    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    .image {
        width: 100%;

        &:hover {
            .image-caption {
                opacity: 1;
                > .inner {
                    transform: translateY(0);
                }
            }
        }
    }

    .image-caption {
        background-color: rgba(0,0,0,0.5);
        color: #fff;
        display: flex;
        font-size: 60%;
        height: 100%;
        left: 0;
        opacity: 0;
        overflow: hidden;
        padding-bottom: 5%;
        padding-left: 11%;
        padding-right: 11%;
        position: absolute;
        top: 0;
        width: 100%;
        transition: opacity 500ms ease-out;

        flex-direction: column;
        justify-content: flex-end;

        > .inner {
            transform: translateY(100%);
            transition: transform .5s ease-out;
        }

        p[class^="font-size-"] {
            @include font-size($h1-font-sizes);
            font-weight: $heading-weight;
            line-height: 1.3;
            text-transform: uppercase;
        }

        a {
            color: #fff;

            &::after {
                background-color: #fff;
            }

            &:hover {
                color: $color-brand2;

                &::after {
                    background-color: $color-brand2;
                }
            }
        }
    }

    @media screen and (min-width: 420px) {
        .image-caption {
            font-size: 80%;
        }
    }

    @media screen and (min-width: 800px) {
        flex-direction: row;

        .col-6 {
            width: 50%;
        }
    }

    @media screen and (min-width: 1024px) {
        .image-caption {
            font-size: 100%;
        }
    }
}
/* END: imagegrid-* */
