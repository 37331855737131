html,
body {
    color: $text-color;
    font-family: $basefont;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    line-height: $line-height-base;
}

h1, h2, h3, h4, h5, h6 {
    color: $heading-color;
    font-family: $heading-font;
    font-weight: $heading-weight;
    line-height: 1.3;
    text-transform: uppercase;

    > a {
        color: inherit;

        &:hover {
            color: $color-brand2;
        }
    }

    &.subheader {
        color: $color-brand2;
        font-size: 18px;
    }
}

h1, h2, h3 {
    line-height: 1.1;

    &:not(.subheader) {
        @include font-size($h1-font-sizes);
        letter-spacing: 1.8px;
    }

    &.subheader {
        @include font-size($h2-font-sizes);
    }
}

// h1 {
//     @include font-size($h1-font-sizes);
// }

// h2 {
//     @include font-size($h2-font-sizes);
//     &:nth-child(2) {
//         @include font-size($h2-subheader-size);
//     }
// }

// h3 {
//     @include font-size($h3-font-sizes);
//     &:nth-child(2) {
//         @include font-size($h3-subheader-size);
//     }
// }

h4 {
    @include font-size($h4-font-sizes);
}

// h5 {
//     @include font-size($h5-font-sizes);
//     &:nth-child(2) {
//         @include font-size($h5-subheader-size);
//     }
// }

a {
    color: $a-color;
    text-decoration: none;
    @include prefix(( transition: color $a-hover-transition-speed ));

    &:hover {
        color: $a-hover-color;
    }
}

p,
main ul,
main ol {
    @include font-size($p-font-sizes);
    line-height: $line-height-base;
    margin-bottom: 15px;
}

strong,
b,
.bold {
    font-weight: 700;
}

.medium {
    font-weight: 500;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup { top: -0.5em; }
sub { bottom: -0.25em; }

.ce-bodytext,
.accordion-content {
    ul {
        list-style: none;

        li {
            padding-left: 1em;

            &::before {
                background-color: $text-color;
                border-radius: 100%;
                content: " ";
                display: block;
                height: 6px;
                left: 0;
                position: absolute;
                top: 0.5em;
                width: 6px;
            }
        }
    }
}

.align-center,
.text-center {
    text-align: center;
}

// .text-right,
.align-right {
    text-align: right;
}

.align-justify,
.text-justify {
    text-align: justify;
}

.indent {
    padding-left: 20%;
}

.color-brand {
    color: $color-brand;
}

.letter-spacing-1,
span.letter-spacing-1,
a.letter-spacing-1 {
    letter-spacing: $letter-spacing-1;
}

.letter-spacing-2,
span.letter-spacing-2,
a.letter-spacing-2 {
    letter-spacing: $letter-spacing-2;
}

.font-size-xlarge {
    @include font-size($font-sizes-xlarge);
}

.font-size-large-lineheight,
.font-size-large {
    @include font-size($font-sizes-large);
}

.font-size-large-lineheight {
    line-height: 1.2;
}

.font-size-medium-large {
    @include font-size($font-sizes-medium-large);
}

.font-size-medium {
    @include font-size($font-sizes-medium);
}

.font-size-small {
    font-weight:400;
    @include font-size($font-sizes-small);
}

.font-size-xsmall {
    font-weight:400;
    @include font-size($font-sizes-xsmall);
}

.blue,
span.blue {
    color: $color-brand;
}
