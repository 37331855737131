.addresses {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;

    a {
        color: inherit;
    }

    .list-group {
        list-style: none;
    }

    .list-group-item {
        color: $color-brand;
    }

    .smalllist {
        max-width: 1100px;
        width: 100%;

        &.list-group {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-align-content: stretch;
            -ms-flex-line-pack: stretch;
            align-content: stretch;
            -webkit-align-items: stretch;
            -ms-flex-align: stretch;
            align-items: stretch;

            .list-group-item {
                max-width: 260px;
                margin: 30px 2%;
            }
        }
    }
}

.tt_address_list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
}

.post-module {
    background: #FFFFFF;
    display: block;
    max-width: 464px;
    margin: 20px;
    min-width: 270px;
    position: relative;
    z-index: 1;
    width: calc(100% - 40px);
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;

    &:hover {
        -webkit-box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.3);
        box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.3);

        .thumbnail img {
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            transform: scale(1.1);
            opacity: 0.6;
        }
    }

    .thumbnail {
        overflow: hidden;

        .date {
            background: $color-grey-dark;
            color: #FFFFFF;
            font-weight: 700;
            height: 55px;
            line-height: 1;
            padding: 12.5px 0;
            position: absolute;
            right: 20px;
            text-align: center;
            top: 20px;
            width: 55px;
            z-index: 1;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            -webkti-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;

            .day {
                font-size: 18px;
            }

            .month {
                font-size: 12px;
                text-transform: uppercase;
            }
        }

        img {
            display: block;
            min-height: 100%;
            object-fit: cover;
            width: 100%;
            -webkit-transition: all 0.3s linear 0s;
            -moz-transition: all 0.3s linear 0s;
            -ms-transition: all 0.3s linear 0s;
            -o-transition: all 0.3s linear 0s;
            transition: all 0.3s linear 0s;
        }
    }

    .post-content {
        background: #FFFFFF;
        padding: 30px;
        width: 100%;
        -webkti-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
        -moz-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
        -ms-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
        -o-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
        transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;

        .category {
            background: $color-grey-dark;
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 600;
            left: 0;
            padding: 10px 15px;
            position: absolute;
            text-transform: uppercase;
            top: 0;
            transform: translateY(-100%);
        }

        .title {
            color: $color-brand;
            font-size: 26px;
            font-weight: 700;
            margin: 0;
            padding: 0 0 10px;
        }

        .sub_title {
            color: $color-brand2;
            display: block;
            font-size: 20px;
            font-weight: 400;
            margin: 0;
            padding: 0 0 20px;
        }

        .description {
            font-size: 14px;
            line-height: 1.8em;
            margin-top: 10px;
        }

        .post-contact-data {
            margin-top: 20px;

            span {
                display: block;
            }
        }

        .post-social {
            display: flex;
            margin-top: 20px;

            a {
                display: inline-block;
                margin: 5px;
                width: 32px;
            }
        }

        .post-meta {
            margin: 18px 0 0;
            color: #999999;

            .timestamp {
                margin: 0 16px 0 0;
            }

            a {
                color: #999999;
                text-decoration: none;
            }
        }
    }

    @media screen and (min-width: 600px) {
        width: calc(50% - 40px);
    }

    @media screen and (min-width: 1200px) {
        width: calc(20% - 40px);

        &.horizontal {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
            max-width: 800px;
            min-width: 550px;

            .thumbnail {
                display: inline-block;
                height: 100%;
                vertical-align: top;
                width: 50%;
            }

            .post-content {
                display: inline-block;
                width: 50%;
            }

            &.without-image {
                .thumbnail {
                    height: 100%;
                    position: absolute;
                    width: 100%;
                }

                .post-content {
                    width: 100%;
                }
            }
        }
    }
}

.be-default_with_side_nav {
    .tt_address_list {
        .post-module {
            @media screen and (min-width: 600px) {
                width: calc(50% - 40px);
            }

            @media screen and (min-width: 1200px) {
                width: calc(33.333% - 40px);
            }

            @media screen and (min-width: 2000) {
                width: calc(25% - 40px);
            }
        }
    }
}
