a[class^="btn-"] {
    display: inline-block;
    @include prefix(( transition: all 500ms ));
}

.btn a,
a.btn {
    color: $text-color;

    &:hover {
        color: $color-brand2;
    }
}

a.btn-default,
.btn-default a,
a.btn-fullwidth,
.btn-fullwidth a {
    background: $color-brand2;
    color: #fff;
    padding: 16px 36px;
    margin: 0;
    font-weight: 500;
    line-height: 1;
    transition: all 250ms;
    text-align: center;
    display: inline-block;

    &:hover { background: $color-brand; }
}

a.btn-fullwidth, .btn-fullwidth a {
    width: 100%;
}

.btn-with-line {
    font-weight: $font-weight-bold;
    letter-spacing: 1px;

    a {
        color: $text-color;
        display: inline-block;

        &::after {
            background-color: #000;
            clear: both;
            content: " ";
            display: block;
            height: 4px;
            max-width: 60px;
            position: relative;
            width: 100%;
            transition: all 500ms;
        }

        &:hover {
            color: $color-brand2;

            &::after {
                background-color: $color-brand2;
                max-width: 100%;
                width: 100%;
            }
        }
    }
}
