.ionav-btn { display:none; position:fixed; right:30px; top:30px; z-index:860; width:30px; height:30px; cursor:pointer; }

.ionav--closed {
	.ionav-btn::before {
		content: "";
	   position: absolute; left:0; top:0.21em; bottom:0.21em; z-index:850;
	   width: 100%;
	   background: linear-gradient(
	     to bottom,
	     black, black 20%,
	     transparent 20%, transparent 40%,
	     black 40%, black 60%,
	     transparent 60%, transparent 80%,
	     black 80%, black 100%
	   );
	}
}

.ionav--open {
	.ionav-btn:hover { opacity: 1; }
	.ionav-btn:before, .ionav-btn:after { position: absolute; left: 15px; content: ' '; height:100%; width:3px; background-color:#fff; }
	.ionav-btn:before { transform: rotate(45deg); }
	.ionav-btn:after { transform: rotate(-45deg); }
}

.ionav-overlay { display:none; position:fixed; left:0; top:0; width:100%; height:100%; overflow:auto; background:rgba(0,0,0,0.5); z-index:840; }
.ionav-hidden { display:none; }

.ionav--active {
	.ionav-source { display:none; }
	.ionav-source.logo, .ionav-source#logo { display:block; }
	.ionav-btn { display:block; }
}

.ionav-canvas {
	width:40%; min-height:100%; max-height:100vw; min-width:240px; background:#fff;

	& > * { padding-top:20px; background-color:#fff; }
	nav {  }
	nav ul { list-style-type:none; }
	nav li {  }
	nav a { display:block; padding:10px; }
	.ionav-logo, #ionav-logo { text-align:center; padding-left:20px; padding-right:20px; }
}

html.ionav--open, .ionav--open body { overflow:hidden; }
.ionav--open .ionav-overlay { display:block; }

.ionav--hasSubnav ul { display:none; }
ul.ionav--hasSubnav.ionav--active { display:block; }
