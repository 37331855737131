#menu-main {
    display: none;
    font-size: $menu-main-font-size;

    a {
        color: $menu-main-color-a;
        display: block;

        &:hover,
        &.act {
            color: $menu-main-color-a-hover;
        }
    }

    > ul {
        display: flex;

        > li {
            > ul {
                background: $menu-main-color-sub-bg;
                display: none;
                position: absolute;

                a {
                    color: $menu-main-color-sub-a;

                    &:hover,
                    &.act {
                        color: $menu-main-color-sub-a-hover;
                    }
                }
            }

            > a {
                line-height: 1;
                padding: 26px;
            }
        }
    }

    li {
        &:hover {
            > ul {
                display: block;
            }
        }

        > a {
            padding: 7px;
        }
    }
}
